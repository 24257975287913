@forward "./main-scss/";
@forward "./listing/listing-common";
@forward "./showcase/showcase";
@use "./config/" as *;
.waf-showcase {
    .layout-wrapper {
        @extend %py-4;
    }
    .waf-head {
        @extend %pb-6;
        @extend %flex;
        .head-tab {
            @extend %d-none;
        }
        .title {
            @extend %uppercase;
            @extend %secondary-800;
            @extend %font-20-pb;
        }
    }
}
.bottom-showcase {
    .layout-wrapper {
        padding-inline: 0;
    }
    .waf-head {
        @extend %d-none;
    }
    .article-list {
        overflow-x: auto;
        @extend %px-4;
        @extend %gap-4;
        @extend %flex;
    }
    .article-item {
        width: 85%;
        flex-shrink: 0;
    }
    .article-title {
        --_line: 2;
    }
}
@include mq(col-md) {
    .bottom-showcase {
        .first-list {
            display: flex;
            padding-inline: 0;
        }
        .article-item {
            width: 35%;
        }
    }
}
@include mq(col-lg) {
    .waf-showcase {
        max-width: var(--container-max-width);
        margin-inline: auto;
        .layout-wrapper {
            flex-wrap: wrap;
        }
        .waf-head {
            padding-bottom: var(--space-3);
            width: 100%;
            .title {
                font-size: 2.4rem;
            }
        }
        .first-list {
            width: calc(60% - var(--space-2));
        }
        .second-list {
            width: calc(40% - var(--space-2));
        }
    }
    .bottom-showcase {
        .layout-wrapper {
            width: 100%;
            flex-shrink: 0;
            margin-inline: auto;
        }
        .first-list {
            width: 100%;
        }
        .article-item {
            width: calc(25% - var(--space-3));
        }
    }
}